@import "variables.scss";

:root {
    --layout-scroll-width: 20px;

    @media screen and (max-width: $mobile) {
        --layout-scroll-width: 0px;
    }

    --layout-columns: 4;
    --layout-column-gap: 12px;

    @media screen and (min-width: $mobile) {
        --layout-columns: 6;
        --layout-column-gap: 20px;
    }

    @media screen and (min-width: ($md)) {
        --layout-columns: 8;
        --layout-column-gap: 20px;
    }

    --vWidth: 100vw;
    @media screen and (min-width: 1340px) {
        --vWidth: 1340px;
    }

    --layout-column-width: calc((var(--vWidth) - var(--layout-scroll-width) - var(--layout-column-gap) * (var(--layout-columns) + 1)) / var(--layout-columns));

    @media screen and (min-width: ($lg)) {
        --layout-columns: 12;
        --layout-column-width: 88.33px;
        --layout-column-gap: 20px;
    }

    --width_x2: calc(var(--layout-column-width) * 2 + var(--layout-column-gap));
    --width_x3: calc(var(--layout-column-width) * 3 + var(--layout-column-gap) * 2);
    --width_x4: calc(var(--layout-column-width) * 4 + var(--layout-column-gap) * 3);
    --width_x5: calc(var(--layout-column-width) * 5 + var(--layout-column-gap) * 4);
    --width_x6: calc(var(--layout-column-width) * 6 + var(--layout-column-gap) * 5);
    --width_x7: calc(var(--layout-column-width) * 7 + var(--layout-column-gap) * 6);
    --width_x9_2: calc(((var(--layout-column-width) * 9 + var(--layout-column-gap) * 8) - var(--layout-column-gap) * 1) / 2);
    --width_x9_4: calc(((var(--layout-column-width) * 9 + var(--layout-column-gap) * 8) - var(--layout-column-gap) * 3) / 4);


    // PRODUCT PUBLIC PAGE LAYOUT
    --layout-product-page-margin: 0px;

    @media screen and (min-width: 1024px) {
        --pwMargin: calc(100vw);
        --layout-product-page-curve: calc(0.033653846 * var(--pwMargin) - 6.461538462px);
        --layout-product-page-margin: clamp(28px, var(--layout-product-page-curve), 42px);
    }

    // PRODUCT PUBLIC PAGE RIGHT SIDE
    // --layout-product-right-side: var(--width_x6);
    @media screen and (min-width: 1024px) {
        --pwRight: calc(100vw - 0px);
        // 0.399038462 * X - 52.615384615 // 1024/1440
        --layout-product-page-right-curve: calc(0.399038462 * var(--pwRight) - 52.615384615px);
        --layout-product-right-side: clamp(356px, var(--layout-product-page-right-curve), 522px);
    }

    // PRODUCT PUBLIC PAGE LEFT SIDE
    @media screen and (min-width: 1024px) {
        --pwLeft: calc(100vw - 0px);

        @media screen and (min-width: 1320px) {
            --pwLeft: calc(1320px - var(--layout-column-gap) * 2);
        }
        @media screen and (min-width: 1440px) {
            --pwLeft: 1280px;
        }
        @media screen and (max-width: 1320px) {
            --pwLeft: calc(100vw - var(--layout-column-gap) * 2);
        }
        // 0,278846154 X + 314,461538462
        --layout-product-left-side: calc(var(--pwLeft) - var(--layout-product-page-margin) - var(--layout-product-right-side));
    }
    @media screen and (max-width: 1023px) {
        --layout-product-left-side: calc(100vw - var(--layout-column-gap) * 2)
    }
}

html {
    @media screen and (max-width: $mobile) {

        /*Scroll bar nav*/
        ::-webkit-scrollbar {
            width: 0;
        }
    }
}