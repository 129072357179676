:root {
  --fontSize: 15px;
}

html {
  /*overflow-x: hidden;*/
  /*overflow-y: scroll;*/
  font-size: var(--fontSize);
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: var(--font-primary);
  color: #191c1f;
}

body {
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh;
}

.Toastify {
  position: absolute;
}

#fb-root {
  position: absolute;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

a {
  -webkit-tap-highlight-color: transparent;
  /* For iOS */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* For Android */
  outline: none;
  /* To remove focus outline */
}

textarea,
input {
  font-family: var(--font-inter);
  font-size: var(--fontSize);
}

input[type="search"] {
  padding: 5px;
  border: none;
  border-radius: 3px;
  margin-right: 10px;
  appearance: none; /* Remove default styles in some browsers */
  -webkit-appearance: none; /* Remove default styles in WebKit browsers like Chrome and Safari */
}

/* Custom style for the search icon */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-clear-button {
  display: none;
}

input[type="search"]::-webkit-search-icon {
  display: none;
}

/* Custom style for the calendar icon */
input[type="date" i]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

* {
  box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  height: 0;
  width: 0;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit; /* 1 */
	font-size: 100%; /* 1 */
	line-height: 1; /* 1 */
	margin: 0; /* 2 */
}

/* Partial css reset */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
