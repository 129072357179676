@import "variables.scss";
@import "mixines.scss";

$palette-black: #191c1f;
$palette-white: #fff;

$palette-default: #e0e0e0;
//$palette-primary: #007AFF;
$palette-primary: #0D7D8B;
//$palette-primary: #06768D;
//$palette-primary-dark: #0061c9;
$palette-primary-dark: #016977;
$palette-secondary: #828B94;
$palette-dark-accent-1: #CBC0AA;
$palette-dark-accent-2: #AF9B72;
$pallete-accent: #FCFAF7;
$palette-background: #F0F2F5;
$palette-background-secondary: #F0F2F5;
$palette-background-opacity: rgba(190, 190, 190, 0.2);
$palette-error: #f8655c;
$palette-warning: #f8655c;
$palette-warning-light: #f7ada9;
$palette-active-warning: #f8655c;
//$palette-info: #0061c9;
//$palette-info-light: #007bff;
//$palette-success: #34c759;
//$palette-active-success: #00c431;
$palette-info: #016977;
$palette-info-light: #2A9AA8;
$palette-success: #0D7D8B;
$palette-active-success: #016977;

$palette-orange: #ff9500;
$palette-light-grey: #E6EAED;
$palette-yellow: #F3D55B;


:root {
  --themes-list: light dark;
  --mobile-size: $mobile;

  @include setVar(--layout-footer-background, #{$palette-light-grey});
  @include setVar(--backdoor-color, "rgba(0, 0, 0, 0.25)");

  @include setVar(--color-hovered, $palette-info);
  @include setVar(--background-hovered, transparent);
  @include setVar(--light-item-hovered, #f0f4f7);
  @include setVar(--dark-item-hovered, #dce0e3);

  // Button colors ============================================================
  @include setVar(--button-border, "1px solid");
  @include setVar(--button-border-radius, 8px);
  @include setVar(--button-padding, 12px 32px);
  @include setVar(--button-font-weight, 700);

  @include setVar(--button-color-primary, #{$palette-primary});
  @include setVar(--button-color-secondary, #{$palette-light-grey});
  @include setVar(--button-color-success, #{$palette-primary});
  @include setVar(--button-color-warning, #{$palette-warning});

  // Text color
  @include setVar(--button-text-color-primary, #{$palette-white});
  @include setVar(--button-text-color-secondary, #191C1F);
  @include setVar(--button-text-color-success, #{$palette-white});
  @include setVar(--button-text-color-warning, #{$palette-white});

  // Hover
  //@include setVar(--button-color-primary-hover, #3395FF);
  @include setVar(--button-color-primary-hover, #2A9AA8);
  @include setVar(--button-color-secondary-hover, #D3D8DB);
  @include setVar(--button-color-success-hover, #2A9AA8);
  @include setVar(--button-color-warning-hover, #F9847D);

  // Pressed button
  //@include setVar(--button-color-primary-pressed, #006CED);
  @include setVar(--button-color-primary-pressed, #016977);
  @include setVar(--button-color-secondary-pressed, #C4C9CC);
  @include setVar(--button-color-success-pressed, #016977);
  @include setVar(--button-color-warning-pressed, #EA4F45);

  // Outline Text color
  @include setVar(--button-outline-text-color-primary, #{$palette-primary});
  @include setVar(--button-outline-text-color-secondary, #191C1F);
  @include setVar(--button-outline-text-color-success, #{$palette-primary});
  @include setVar(--button-outline-text-color-warning, #{$palette-warning});

  // Outline variant with hover
  //@include setVar(--button-outline-primary-hover, #E9F2FC);
  @include setVar(--button-outline-primary-hover, #E0F0F2);
  @include setVar(--button-outline-secondary-hover, #F0F4F7);
  @include setVar(--button-outline-success-hover, #EDFCEB);
  @include setVar(--button-outline-warning-hover, #FCF1F0);

  //Outline variant pressed
  //@include setVar(--button-outline-primary-pressed, #D2E2F4);
  @include setVar(--button-outline-primary-pressed, #C2E0E4);
  @include setVar(--button-outline-secondary-pressed, #E6EAED);
  @include setVar(--button-outline-success-pressed, #D8F4D5);
  @include setVar(--button-outline-warning-pressed, #FADEDC);

  // Text variant colors
  @include setVar(--button-text-primary, #{$palette-primary});
  @include setVar(--button-text-secondary, #191C1F);
  @include setVar(--button-text-success, #{$palette-primary});
  @include setVar(--button-text-warning, #{$palette-warning});

  // Disabled button
  @include setVar(--button-disabled-color, #F0F2F5);
  @include setVar(--button-disabled-text-color, #828B94);

  // Outline variant disabled button
  @include setVar(--button-outline-disabled-color, #{$palette-white});
  @include setVar(--button-outline-disabled-border-color, #{$palette-light-grey});
  @include setVar(--button-outline-disabled-text-color, #{$palette-secondary});
  // ============================================================================

  @include setVar(--link-color, #{$palette-secondary});

  @include setVar(--input-height, 40px);
  @include setVar(--input-border, "0px solid #{$palette-dark-accent-1}");
  //@include setVar(--input-outline, "1px solid transparent");
  @include setVar(--input-outline-transparent, "1px solid transparent");
  @include setVar(--input-outline, "1px solid #{$palette-black}");
  @include setVar(--input-empty-outline, "1px solid #{$palette-secondary}");
  @include setVar(--input-border-radius, 4px);
  @include setVar(--input-color, $palette-black);
  //@include setVar(--input-background-color, #{$palette-background});
  @include setVar(--input-background-color, #{$palette-white});
  @include setVar(--input-empty-background-color, $palette-white);
  // @include setVar(--input-outline-color, $palette-secondary);
  //@include setVar(--input-outline-color-active, $palette-primary);
  @include setVar(--input-outline-color-active, $palette-black);
  @include setVar(--input-placeholder-color, $palette-secondary);
  //@include setVar(--input-icon-color, $palette-primary);
  @include setVar(--input-icon-color, $palette-black);
  @include setVar(--input-focused-background-color, $palette-background);
  @include setVar(--input-disabled-background-color, "#F2F4F7");
  @include setVar(--input-outline-color-error, "#D6B0AE");
  @include setVar(--input-error-background-color, "rgba(248, 101, 92, 0.05)");
  @include setVar(--input-shadow, "none");
  @include setVar(--input-transition, all 0.3s);

  @include setVar(--checkbox-black-color, #{$palette-black});
  @include setVar(--checkbox-white-color, #{$palette-white});
  @include setVar(--checkbox-dark-color, #{$palette-black});
  @include setVar(--checkbox-border-radius, "4px");

  @include setVar(--field-label-color, #{$palette-black});
  @include setVar(--field-color-error, #{$palette-error});

  @include setVar(--switch-color, "#E6EAED");
  @include setVar(--switch-color-active, #{$palette-success});
  @include setVar(--switch-color-disabled, "#E6EAED");

  @include setVar(--modal-border-radius, 8px);
  @include setVar(--modal-background,  $palette-white);
  @include setVar(--modal-shadow, "0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)");
  @include setVar(--modal-padding, 32px);
  @include setVar(--modal-small-padding, 24px);
  @include setVar(--modal-confirm-padding, 32px);

  @include setVar(--toast-background-color-info, $palette-primary !important);
  @include setVar(--toast-background-color-success, $palette-primary !important);
  @include setVar(--toast-background-color-warning, rgb(248, 101, 92) !important);
  @include setVar(--toast-background-color-error, rgb(248, 101, 92) !important);

  @include setVar(--header-shadow, "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)");


  @include setVar(--filter-border-radius, 4px);
  @include setVar(--image-border-radius, 4px);


  @include setVar(--card-border-radius, 4px);
  @include setVar(--card-border, 1px solid #EBEEF0);
  @include setVar(--card-border-2, 1px solid #E6EAED);
  @include setVar(--card-mobile-border-radius, 4px);
  @include setVar(--card-shadow, "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)");
  @include setVar(--logo-border-radius, 8px);
  @include setVar(--popup-border-radius, 16px);
  @include setVar(--popup-border, 0px solid transparent);
  @include setVar(--tree-shadow, "0px 15px 32px rgba(0, 0, 0, 0.04), 0px 12px 24px rgba(0, 0, 141, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04)");
  @include setVar(--popup-shadow, "0px -24px 32px rgba(0, 0, 0, 0.04), 0px -16px 24px rgba(0, 0, 0, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)");
  @include setVar(--popup-filter-shadow, "0px 12px 32px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)");

  @include setVar(--logo-shadow, "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)");
  @include setVar(--popup-catalogue-shadow, "0px 8px 40px rgba(0, 0, 0, 0.05), 0px 8px 28px rgba(0, 0, 0, 0.05), 0px 2px 12px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05)");
  @include setVar(--popup-background-color, white);
  @include setVar(--tabs-background-color, #{$palette-white});
  @include setVar(--rating-color, #{$palette-orange});


  @include setVar(--text-color-active, #{$palette-info});
  @include setVar(--text-color-warning, #{$palette-warning});

  @include setVar(--icon-color, #{$palette-primary});
  @include setVar(--icon-color-active, #{$palette-info});
  @include setVar(--icon-color-disabled, #{$palette-secondary});
  @include setVar(--icon-shadow, "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)");

  @include setVar(--color-white, #{$palette-white});
  @include setVar(--color-black, #{$palette-black});
  @include setVar(--color-default, #{$palette-default});
  @include setVar(--color-primary, #{$palette-primary});
  @include setVar(--color-primary-dark, #{$palette-primary-dark});
  @include setVar(--color-secondary, #{$palette-secondary});
  @include setVar(--color-info, #{$palette-info});
  @include setVar(--color-info-light, #{$palette-info-light});
  @include setVar(--color-error, #{$palette-error});
  @include setVar(--color-warning, #{$palette-error});
  @include setVar(--color-warning-light, #{$palette-warning-light});
  @include setVar(--color-success, #{$palette-success});
  @include setVar(--color-waiting, #{$palette-orange});
  @include setVar(--color-orange, #{$palette-orange});
  @include setVar(--color-light-grey, #{$palette-light-grey});
  @include setVar(--color-yellow, #{$palette-yellow});

  @include setVar(--scroll-trumb-color, #d9d9d9);
  @include setVar(--scroll-thumb-color-hovered, #c9c9c9);
  @include setVar(--scroll-track-color, #{transparent});

  @include setVar(--opacity-value, 0.7);
}

.light {
  @include setVar(--common-background-color, #{$palette-background-secondary});
  @include setVar(--white-background-color, #{$palette-white});
  @include setVar(--black-background-color, #{$palette-black});
  @include setVar(--background-color, #{$palette-background});
  @include setVar(--secondary-background-color, #{$palette-background-secondary});
  @include setVar(--opacity-background-color, #{$palette-background-opacity});
  @include setVar(--card-background-color, #{$palette-white});

  @include setVar(--text-color, #{$palette-primary});
  @include setVar(--text-color-bold, #{$palette-black});
  @include setVar(--color-highlight, #dce0e3);

  @include setVar(--font-size-sm, 13px);
}

.dark {}
