@use "sass:meta";
@use "sass:string";

// theme

$prefix_theme: "mallprix";

@mixin setVar($var, $val) {
  // Add prefix for css variable
  // $var - css variable, example: --primary-color etc
  // $val - css value, example: 1px, red, solid etc
  #{unquote("--#{$prefix_theme}-" + str-slice($var, 3))}: unquote("#{$val}");

}

@mixin setProperty($property, $var, $important: false) {
  // Add prefix for css variable
  // $property - css property, example: border-radius, background etc
  // $var - css variable, example: --primary-color etc

  @if $important {
    #{$property}: var(unquote("--#{$prefix_theme}-" + str-slice($var, 3))) !important;
  }

  @else {
    #{$property}: var(unquote("--#{$prefix_theme}-" + str-slice($var, 3)));
  }
}

//other

@mixin fontBold($fontWeigth: 700) {
  font-weight: $fontWeigth;
  @include setProperty(color, --text-color-bold);
}

@mixin notDisplayForSmallScreen($maxWidth: $md) {
  @media screen and (max-width: $maxWidth) {
    display: none;
  }
}

@mixin margin($value) {
  margin: $value;

  @media screen and (max-width: $xsm) {
    margin: $value * 2;
  }
}

@mixin prop($property, $value, $maxWidth: $xsm) {
  #{$property}: $value;

  @media screen and (max-width: $maxWidth) {
    #{$property}: $value * 2;
  }
}

@mixin hiddenScroll() {
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

@mixin scrollable($width: 0.46875rem, $important: false) {
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: #919dac transparent;

    &:hover {
      scrollbar-color: #777 transparent;
    }
    scrollbar-width: thin;

    @if $important {
      scrollbar-width: thin !important;
    }

    @else {
      scrollbar-width: thin;
    }
  }

  &::-webkit-scrollbar {
    @if $important {
      width: $width !important;
      height: $width !important;
    }

    @else {
      width: $width;
      height: $width;
    }

    display: block;
  }

  &::-webkit-scrollbar-track {
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #919dac;
    border-radius: 0;
    display: block;

    &:hover {
      background-color: #777;
    }
  }
}

@mixin circle($size: 48px) {
  width: $size;
  height: $size;
  border-radius: $size / 2;
}

@mixin horizontalScroll {
  display: flex;
  align-items: center;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

@mixin headerMobile {
  display: flex;
  height: 48px;
  padding: 0 10px;
  @include setProperty(background-color, --white-background-color);
  align-items: center;
  @include setProperty(box-shadow, --header-shadow);
}

@mixin hovered ($underline: false, $changeOpacity: false, $important: false) {
  opacity: 1;
  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    @if $underline {
      @if $important {
        text-decoration: underline !important;
      }
      @else {
        text-decoration: underline;
      }
    }

    @if $changeOpacity {
      @include setProperty(opacity, --opacity-value, $important);
    }
  }
}

@mixin hoveredCustom ($color: --color-hovered, $background: --background-hovered, $underline: false, $important: false) {
  opacity: 1;
  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    @include setProperty(color, $color, $important);
    @include setProperty(background-color, $background, $important);

    @if $underline {
      @if $important {
        text-decoration: underline !important;
      }
      @else {
        text-decoration: underline;
      }
    }
  }
}

@mixin inactive {
  @include setProperty(opacity, --opacity-value);
  pointer-events: none;
}

@mixin highlight {
  cursor: pointer;

  &:active {
    @include setProperty(background-color, --color-highlight);
  }
}

@mixin buttonBuy {
  height: 44px;
  font-size: 1rem;
  line-height: 3rem;
  @include fontBold();
  text-align: center;
  @include setProperty(color, --white-background-color);
  @include setProperty(border-radius, --button-border-radius);
  //@include setProperty(background-color, --button-buy-color);
  transition: 0.2s;
  //@include setProperty(box-shadow, --button-buy-shadow);
  //@include highlight;

  &:hover {
    //@include setProperty(background-color, --button-buy-active-color);
  }

  @media screen and (min-width: $mobile) {
    min-width: 215px;
  }
}

@mixin textLink {
  @include setProperty(color, --link-color);
  text-decoration: underline;
}

@mixin input {
  @include setProperty(color, --text-color);
  @include setProperty(border-radius, --input-border-radius);
  @include setProperty(border, --input-border);
  @include setProperty(background-color, --input-background-color);
  font-size: 1rem;
  height: 2.5rem;
}

@mixin card {
  overflow: hidden;
  @include setProperty(border-radius, --card-border-radius);
  @include setProperty(background-color, --card-background-color);
  font-size: 1rem;
  margin: 16px auto;
  margin-top: 5px;

  @media screen and (max-width: $mobile) {
    margin: 2px auto;
    @include setProperty(border-radius, --card-mobile-border-radius);
  }
}

@mixin ellipsisMultiLines($lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
}

@mixin gridColumns($cols: 2, $gap: 2rem) {
  -webkit-columns: $cols;
  -moz-columns: $cols;
  columns: $cols;
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin textPrice {
  font-size: 2rem;
  @include fontBold();
  line-height: 1;
  @include setProperty(color, --text-color-bold);

  @media screen and (max-width: $mobile) {
    font-size: 1.3rem;
    @include fontBold(700);
  }
}

@mixin textDiscount {
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration: line-through;
  @include setProperty(color, --color-secondary);

  @media screen and (max-width: $mobile) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

@mixin textDiscountPercent {
  padding: 0 4px;
  font-size: 1rem;
  line-height: 1.8;
  border-width: 1px;
  border-style: solid;
  @include setProperty(color, --white-background-color);
  @include setProperty(border-color, --text-color-warning);
  @include setProperty(background-color, --text-color-warning);
  @include setProperty(border-radius, --input-border-radius);

  @media screen and (max-width: $mobile) {
    height: 18px;
    font-size: 12px;
    line-height: 1.5;
  }
}

@mixin tag {
  display: flex;
  padding: 4px 8px 4px 12px;
  align-items: center;
  @include setProperty(background-color, --background-color);
  border: 1px solid #ebeef0;
  box-sizing: border-box;
  border-radius: 200px;
  justify-content: center;

  .text {
    margin-right: 4px;
  }

  button {
    width: 20px;
    height: 20px;
  }
}

@mixin flexColumn($align: flex-start, $justify: flex-start) {
  display: flex;
  flex-direction: column;
}

@mixin tooltipBasic() {
  position: relative;

  &::after {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    content: "";
    display: block;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    right: calc(100% - 4px);
    border-inline-start: 6px solid #000;
    border-inline-end-width: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-inline-end: 6px solid transparent;
    background-color: transparent;
    transition: all 0.2s ease;
    z-index: 99998;
  }

  &::before {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: all 0.2s ease;
    z-index: 99998;

    content: attr(data-title);
    font-size: 11px;
    line-height: 12px;
    white-space: nowrap;
    background-color: #000;
    color: #fff;
    padding: 5px 8px;
    transform: translateY(-50%);
    bottom: auto;
    right: calc(100% + 8px);
    display: block;
    left: auto;
    top: 50%;
  }

}

@mixin tooltipShow() {

  &::after,
  &::before {
    opacity: 1;
  }
}

@mixin tooltipLeft() {
  @include tooltipBasic();
}

@mixin tooltipRight() {
  @include tooltipBasic();

  &::before {
    left: calc(100% + 8px);
    right: unset;
  }

  &::after {
    left: calc(100% - 4px);
    right: unset;
    transform: translateY(-50%) scaleX(-1);
  }
}

@mixin mediaParameter($property, $valLg, $valMd, $valSm, $valXs, $important: false) {
  @if $important {
    #{$property}: $valLg !important;
  } @else {
    #{$property}: $valLg;
  }

  @media screen and (max-width: ($lg - 1px)) {
    @if $important {
      #{$property}: $valMd !important;
    } @else {
      #{$property}: $valMd;
    }
  }

  @media screen and (max-width: ($md - 1px)) {
    @if $important {
      #{$property}: $valSm !important;
    } @else {
      #{$property}: $valSm;
    }
  }

  @media screen and (max-width: ($sm - 1px)) {
    @if $important {
      #{$property}: $valXs !important;
    } @else {
      #{$property}: $valXs;
    }
  }
}

@mixin mediaParameterXs($property, $val, $important: false) {

  @media screen and (max-width: ($xsm - 1px)) {
    @if $important {
      #{$property}: $val !important;
    }

    @else {
      #{$property}: $val;
    }
  }

}

@mixin mobileParameter($property, $val, $important: false) {

  @media screen and (max-width: ($sm - 1px)) {
    @if $important {
      #{$property}: $val !important;
    }

    @else {
      #{$property}: $val;
    }
  }

}

@mixin mediaParameterSm($property, $val, $important: false) {

  @media screen and (max-width: ($md - 1px)) {
    @if $important {
      #{$property}: $val !important;
    }

    @else {
      #{$property}: $val;
    }
  }

}

@mixin mediaParameterMd($property, $val, $important: false) {

  @media screen and (max-width: ($lg - 1px)) {
    @if $important {
      #{$property}: $val !important;
    }

    @else {
      #{$property}: $val;
    }
  }

}

@mixin popup() {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .backdor {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.295);
  }
}

@mixin popupBox() {
  background: white;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05), 0px 8px 28px rgba(0, 0, 0, 0.05), 0px 2px 12px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

@mixin loaderCentered() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

@mixin fontLabelSmall() {
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.06px;
}

@mixin fontHeadlineH4() {
  font-family: var(--font-inter);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
}

@mixin fontHeadlineH5() {
  font-family: var(--font-inter);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.15px;
}

@mixin fontHeadlineH6() {
  font-family: var(--font-inter);
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.05px;
}

@mixin fontBodyBig() {
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

@mixin fontSubtitleS1 {
  font-family: var(--font-inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin fontSubtitleS2 {
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin fontSubtitleS3 {
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin labelFontSize() {
  font-size: 15px;
  line-height: 20px;
}

@mixin labelGray() {
  @include labelFontSize;
  letter-spacing: 0;
  @include setProperty(color, --color-secondary);
}

@mixin fontBodyMediumRegular {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin fontBodyMediumBold {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

@mixin fontBodySmallRegular {
  @include setProperty(font-size, --font-size-sm);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.06px;
}

@mixin fontBodySmallBold {
  @include setProperty(font-size, --font-size-sm);
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.06px;
}

// Layout

@mixin width_x2() {
  width: var(--width_x2);
  min-width: var(--width_x2);
}

@mixin height_x2() {
  height: var(--width_x2);
  min-height: var(--width_x2);
}

@mixin width_x3() {
  width: var(--width_x3);
  min-width: var(--width_x3);
}

@mixin height_x3() {
  height: var(--width_x3);
  min-height: var(--width_x3);
}

@mixin width_x4() {
  width: var(--width_x4);
  min-width: var(--width_x4);
}

@mixin height_x4() {
  height: var(--width_x4);
  min-height: var(--width_x4);
}

@mixin width_x6() {
  width: var(--width_x6);
  min-width: var(--width_x6);
}

@mixin width_x9_2() {
  width: var(--width_x9_2);
  min-width: var(--width_x9_2);
}

@mixin height_x9_2() {
  height: var(--width_x9_2);
  min-height: var(--width_x9_2);
}

@mixin width_x9_4() {
  width: var(--width_x9_4);
  min-width: var(--width_x9_4);
}

@mixin height_x9_4() {
  height: var(--width_x9_4);
  min-height: var(--width_x9_4);
}

// TODO doesn't work
@function widthByCols($cols) {
  @return calc(var(--layout-column-width) * $cols + var(--layout-column-gap) * ($cols - 1));
}

// START ======== Products list mixines
@mixin pageContentBottom() {
  @include mediaParameter(padding-bottom, 64px, 64px, 64px, 40px, true);
}

@mixin productsSidePanel() {
  @include width_x3();

  @media screen and (max-width: ($lg - 1)) {
    @include width_x2();
  }

  // @include mediaParameter(width, 305px, 230px, 230px, 100%);
  margin-right: var(--layout-column-gap);
  //margin-bottom: 16px;
  @include mobileParameter(margin-right, unset);
  @include mobileParameter(display, none);
}

@mixin productsListContainer() {
  display: flex;
  flex-wrap: wrap;
  gap: var(--layout-column-gap);

  /*
  // dividing lines
  @include mobileParameter(gap, 1px);
  @include mobileParameter(background-color, #E6EAED);
  */
}

@mixin homeProductWrapItem() {
  &>div {
    width: 100%;
  }

  @include width_x2();
  // @include mobileParameter(padding-bottom, 7px); // dividing lines

  &>div>div:first-child {
    // Product picture
    @include height_x2();
    // @include mobileParameter(background-color, unset); // dividing lines
  }

  /*
  // dividing lines

  &:nth-child(1n)>div>div:last-child {
    @include mobileParameter(padding-right, 8px);
  }

  &:nth-child(2n)>div>div:last-child {
    @include mobileParameter(padding-left, 8px);
  }

  @include mobileParameter(width, calc(50% - 0.5px));
  @include mobileParameter(min-width, calc(50% - 0.5px));
  @include mobileParameter(background-color, white);
  */
}

@mixin productWrapItem() {
  &>div {
    width: 100%;
  }

  @include width_x9_4();

  // @include mobileParameter(padding-bottom, 7px); // dividing lines

  &>div>div:first-child {
    @include height_x9_4();
    // @include mobileParameter(background-color, unset); // dividing lines
  }

  /*
  // dividing lines
  &:nth-child(1n)>div>div {
    @include mobileParameter(padding-right, 8px);
  }

  &:nth-child(2n)>div>div {
    @include mobileParameter(padding-left, 8px);
  }
  */

  @media screen and (max-width: ($lg - 1)) {
    @include width_x2();

    &>div>div:first-child {
      // Product picture
      @include height_x2();
    }
  }

  /*
  // dividing lines
  @include mobileParameter(width, calc(50% - 0.5px));
  @include mobileParameter(min-width, calc(50% - 0.5px));
  @include mobileParameter(background-color, white);
  */
}

// END ======== Products list mixines

@mixin draftItem() {
  opacity: 0.7;
  filter: grayscale(0.5);
}

@mixin tableWithBorders() {
  $tableBorder: 1px solid #D1D1D6;
  border-collapse: separate;
  border-spacing: 0;

  th {
    border-top: $tableBorder;
    border-bottom: $tableBorder;
    border-right: $tableBorder;
  }

  td {
    border-bottom: $tableBorder;
    border-right: $tableBorder;
  }

  th:first-child,
  td:first-child {
    border-left: $tableBorder;
  }
}

@mixin boxWithBorders {
  @include setProperty(border-radius, --button-border-radius);
  border: 1px solid;
  border-color: #E6EAED;
  @include hovered();
}

@mixin crossIcon($color: --color-warning, $size: 16px, $width: 2px) {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    left: calc($size / 2 - $width / 2);
    content: " ";
    height: $size;
    width: $width;
    @include setProperty(background-color, --color-warning);
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

@mixin shadowForLineLimits() {
  position: absolute;
  bottom: 0;
  height: 3rem;
  width: 100%;
  background: linear-gradient(0deg, white, transparent);
}
